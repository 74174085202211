import '@mantine/core/styles.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import App from './App';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://09cbff9678eccdfd5bb3869a431ba083@sentry.usernames.center/2",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.usernames\.center/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Create a root and render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MantineProvider
    defaultColorScheme="dark"
  >
    <App />
  </MantineProvider>
);
