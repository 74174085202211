import { useEffect, useState } from 'react';

export const useIsTelegram = () => {
  const [isTelegram, setIsTelegram] = useState(false);

  useEffect(() => {
    const { hash } = window.location;
    if (hash.indexOf('tgWebAppData') !== -1) {
      window.localStorage.setItem('webapp_init_data', hash);
      const tgWebAppData = (new URLSearchParams(hash.split('#')[1])).get('tgWebAppData');
      window.localStorage.setItem('tgWebAppData', tgWebAppData);
      const user = (new URLSearchParams(tgWebAppData)).get('user');
      window.localStorage.setItem('user', user);
      setIsTelegram(true);
    } else if (window.localStorage.getItem('webapp_init_data') !== null && window.localStorage.getItem('user') !== null) {
      setIsTelegram(true);
    }
  }, []);

  return isTelegram;
};
